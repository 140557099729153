import Countdown from './Countdown';
import './App.css';

function App() {
  return (
    <div className="App">
      <Countdown />
    </div>
  );
}

export default App;
